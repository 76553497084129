<template>
  <div class="book-all-detail">
    <img
      class="img1"
      src="../../../assets/images/bookbag.png"
      alt=""
      width="100%"
    />
    <img
      class="img2"
      src="../../../assets/images/bookimg.png"
      alt=""
      width="100%"
    />
    <div class="contain">
      <div class="left">
        <p class="Rem0_21">联系我们购买书籍</p>
        <img src="../../../assets/images/bookimg2.png" alt="" />
        <p class="Rem0_19">扫一扫添加微信购买</p>
      </div>
      <div class="right">
        <div>
          <p class="Rem0_19 title">更多书籍</p>
          <div class="book-list">
            <div class="book" v-for="(item, index) in currentList" :key="index">
              <img :src="item.img" alt="" />
              <div>
                <p class="Rem0_19 p1">《{{ item.bookName }}》</p>
                <p class="p2">
                  {{ item.bookDetail }}
                </p>
                <el-button @click="toPage(item)">查看详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <Pagination :num="numpage" :limit="limit" @getNew="getNew"></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      numpage: 0, //总页数
      limit: 6, // 一页显示多少条
      bookList: [
        {
          img: require("../../../assets/images/book2.png"),
          bookName: "管理体系数字化转型实践",
          price: "暂无报价",
          bookDetail:
            "本书提出数字经济下的数字体系概念，进而引申出先进体系的建设方法和思维方式，并提出一种普适性的数字体系建设方法，使企业数字化转型具有操作性，同时从结构设计开始的观点，介绍了从管理模式展开的经营路线和新型价值链两个关键的顶层结构。同时，由于数字体系与智能制造密不可分，本书给出了数字体系建设和转型的方向，并率先研究智能化制造与数字化体系的关系。",
          textContain: `  
             <p class="bookText">
              为打造数字经济新优势，加快推动数字产业化，2021年3月国家公布了“十四五规划”。该规划勾勒出了我国数字经济发展的宏伟蓝图，为数字经济发展指明了方向、注入了动力。在此背景下，多方正进一步加强顶层设计，推出“十四五”数字经济专项规划、新型基础设施建设规划及一系列重磅落地的扶持政策，各地方政府也在积极部署并推进数字化转型的战略目标。在数字经济迅猛发展势头的带动下，我国正掀起一轮数字化转型热潮。但数字化涉及的面很广，如软件系统、网络、平台、基础设施等，还有应用数字技术的数字化营销、数字化财务、数字化运营、数字化执行，甚至是数字化组织、数字化领导力等，所有这些数字化都是转型中必然要面对的。不过，万法归宗，都要回归到原点——管理体系——的转型上寻找普适性的方法。就企业而言，管理体系是企业持续发展的基石，是提升经济效益的保证，是转型升级的核心，这比其他方面的转型更为重要。如何转型？这是摆在企业面前一道急需破解的难题。作者一直从事企业管理、标准化管理及信息化方面的咨询工作，想在数字化转型方面做个先行者，探索数字化管理的新方法，以帮助企业转型，因此编写了本书。
              </p>
              <div class="bookSection">
                <p>【本书分为7个章节：】</p>
                <p><span>第一章</span><span>数字化管理体系</span></p>
                <p><span>第二章</span><span>三元素</span></p>
                <p><span>第三章</span><span>数字体系的过程设计</span></p>
                <p><span>第四章</span><span>过程数字化</span></p>
                <p><span>第五章</span><span>数字体系柔性化</span></p>
                <p><span>第六章</span><span>数字体系运行与绩效</span></p>
                <p><span>第七章</span><span>数字体系成熟</span></p>
              </div>
              `,
        },
        {
          img: require("../../../assets/images/book3.png"),
          bookName: "过程方法",
          price: "¥55",
          bookDetail:
            "要打造一流的企业，就是要打造一流的过程。本书自始至终都在体现目前流行的“过程方法”的应用，并融入多年的实践经验与探索。相信对正要进行流程优化、完善和提升管理体系的企业有所帮助！",
          textContain: `  
             <p class="bookText">
                笔者已于2011年出版过《过程管理方法》一书，其主要的内容是介绍流程设计和优化的“过程方法”，已得到了读者的普遍认可。2015年国际标准化组织颁布了新版的ISO9001标准，已经明确组织在建立体系时要采用过程方法，证明笔者的多年前的研究已经成为主流思想，再结合这几年来的经验总结，该书再版书名就改为《过程方法》，并将原来第一章分成两个章，将过程风险分析的内容独立成一章——过程风险，其他章节的内容也相应做了修改。
              </p>
              <p class="bookText">
                很多企业面临困境的时候很容易就会想到流程优化。不过，对流程优化跃跃欲试的企业只钟情于成功的一面，殊不知不成功的例子比成功的多得多。不是没有决心，不是没有专家，而是每次的实践都不一样，每次都摸着石头过河，而真正的主角不是专家而是企业自己，专家能有三成的帮助已经了不起了。那么，能否有一种方法完全由企业自己去主导过程优化呢？笔者一直在探索这种方法，本书向大家呈现的就是它的研究成果。
              </p>
              <div class="bookSection">
                <p>【本书分为10个章节：】</p>
                <p><span>第一章</span><span>过程的基本概念</span></p>
                <p><span>第二章</span><span>管理体系模式</span></p>
                <p><span>第三章</span><span>过程识别</span></p>
                <p><span>第四章</span><span>过程风险分析</span></p>
                <p><span>第五章</span><span>过程职责</span></p>
                <p><span>第六章</span><span>过程接口</span></p>
                <p><span>第七章</span><span>过程业绩</span></p>
                <p><span>第八章</span><span>过程简化</span></p>
                <p><span>第九章</span><span>体系优化</span></p>
                <p><span>第十章</span><span>审核过程方法</span></p>
                <p><span>第十一章</span><span>管理评审的过程方法</span></p>
              </div>
              `,
        },
        {
          img: require("../../../assets/images/book4.png"),
          bookName: "过程管理方法",
          price: "¥35",
          bookDetail:
            "要打造一流的企业，就是要打造一流的过程。本书自始至终都在体现目前流行的“过程方法”的应用，并融入多年的实践经验与探索。相信对正要进行流程优化、完善和提升管理体系的企业有所帮助！",
          textContain: `<p class="bookText">
          很多企业面临困境的时候很容易就会想到流程优化。不过，对流程优化跃跃欲试的企业只钟情于成功的一面，殊不知不成功的例子比成功的多得多。不是没有决心，不是没有专家，而是每次的实践都不一样，每次都摸着石头过河，而真正的主角不是专家而是企业自己，专家能有三成的帮助已经了不起了。那么，能否有一种方法完全由企业自己去主导过程优化呢？笔者一直在探索这种方法，本书向大家呈现的就是它的研究成果。
              </p>
              <div class="bookSection">
                <p>【本书分为10个章节：】</p>
        <p><span>第一章</span><span>过程的基本概念</span></p>
        <p><span>第二章</span><span>过程识别</span></p>
        <p><span>第三章</span><span>过程基本要素</span></p>
        <p><span>第四章</span><span>过程接口</span></p>
        <p><span>第五章</span><span>过程业绩</span></p>
        <p><span>第六章</span><span>过程控制四个层次</span></p>
        <p><span>第七章</span><span>过程职责优化</span></p>
        <p><span>第八章</span><span>系统优化</span></p>
        <p><span>第九章</span><span>审核的过程方法</span></p>
        <p><span>第十章</span><span>管理评审的过程方法</span></p>
              </div> `,
        },
        {
          img: require("../../../assets/images/book9.png"),
          bookName: "企业商业机密保护的最佳实践",
          price: "¥28",
          bookDetail:
            "本书是专门为广大中小型介绍如何快速、有效地建立一系列“小而精”的管理手段，以保护公司核心商业机密。这是我司长期为多家企业提供信息安全咨询的实践总结。",
          textContain: `<p class="bookText">商业信息和技术信息是企业重要的无形资产，对企业的生存和发展有着重要的影响。但是随着市场竞争日趋激烈，对商业机密的争夺也变得无所不用其极，企业对商业机密保护的重视程度与日俱增。
               但对于一些中小企业来说，建立一套完备的信息安全管理体系耗时长、投入大、人员能力跟不仧，他们只需要一套“小而精"的管理手段，快速、有效地来保守公司最核心的商业机密。
             </p>
             <p class="bookText">本书正是为有这种现实需要的企业而编写的，本书是我公司长期为多家企业建立信息安全管理体系或单纯的商业机密保护体系的实践总结。我们在建立体系的过程中，应用了“过程方法”这一国际公认的最佳实效方法，首家独创把过程方法与ISO/IEC27001的最佳实践结合起来，根据企业的实际情况和需求，“量力而为”，能快速地建立最有效的保护方法。我们把这些经验和实例汇编在本书中提供借鉴，并启发读者如何去运用过程方法ISO/IEC27001标准，使建立出来的信息安全管理体系更有效、更符合企业自身的特点，而非生搬硬套其他公司的经验和方法。</p>
             <div class="bookSection">
                <p>【本书分为7个章节：】</p>
                <p><span>第一章</span><span>商业机密保护的过程方法</span></p>
                <p><span>第二章</span><span>怎样发现泄密风险</span></p>
                <p><span>第三章</span><span>商业机密的保护制度</span></p>
                <p><span>第四章</span><span>人力资源防泄密</span></p>
                <p><span>第五章</span><span>技术辅助</span></p>
                <p><span>第六章</span><span>应急处理和法律运用</span></p>
                <p><span>第七章</span><span>自我检查和完善</span></p>
              </div>
`,
        },
        {
          img: require("../../../assets/images/book11.png"),
          bookName: "服务质量FMEA差距模型及应用",
          price: "¥15",
          bookDetail:
            "本书介绍的是一种服务质量分析的具体方法，帮助服务型企业策划服务质量，以服务在第一次做好为目的。",
          textContain: `  
             <p class="bookText">
              工业发展到今天，只要提到产品设计、可靠性、缺陷预防就会想到一种有效的应用工具失效模式和后果分析，它被称为FMEA（FailureModeandEffectsAnalysis)。FMEA是在20世纪50年代开始被应用到航天领域的，它颠覆了产品设计的传统模式，是一种创举。其原理是，在产品设计时首先设想产品有哪些潜在故障，不管发生过还是没有发生过都尽可能地列出来，并对其后果进行分析，然后针对危害大风险高的故障通过设计避免其发生，这样就能基本上做到80％的问题在设计阶段得到解决。
              </p>
              <p class="textIndent">由于FMEA成效显著，而今不仅在军工、汽车、医疗这些可靠性要求高的产品上得到广泛应用，而且也普遍地应用民用产品了，它已成为一种非常成熟的工具，有关的书籍也不少。正因为此，不少领域也在移植它的原理，例如，安全管理、环境管理、信息管理、设备管理等。</p>
              <p class="bookText">本书作者通过具有特色的服务蓝图展示了服务界面、服务功能以及服务过程的所有活动。从理论上讲每一个活动都存在失效模式，在服务蓝图的基础上所开发的服务FMEA就能很好地解决日常服务中的很多漏洞。开发服务FMEA实质上是开发零缺陷的服务，是开发有效的控制手段，是开发和完善服务特性的，那时你就会发现一次性
              做好服务的秘密了。这正是本书价值所在。
              </p>
              <p class="textIndent">所以，作为防错手段，服务FMEA应成为服务设计的重要组成部分。服务FMEA是方法论，本书的很多方法我们己经做过多次尝试，经过验证是可行的，能够指导服务提供商的实战操练。相信本书会对银行、保险、电信、运输、物流、美容等高风险服务行业以及层出不穷的各类新兴服务业有很大的帮助。</p>
              <div class="bookSection">
                <p>【本书分为8个章节：】</p>
                <p><span>第一章</span><span>服务导论</span></p>
                <p><span>第二章</span><span>顾客不满意带来的经营风险</span></p>
                <p><span>第三章</span><span>FMEA基础</span></p>
                <p><span>第四章</span><span>服务质量FMEA差距模型</span></p>
                <p><span>第五章</span><span>FMEA实施前的准备工作</span></p>
                <p><span>第六章</span><span>服务FMEA的实施步骤</span></p>
                <p><span>第七章</span><span>FMEA在物业服务中的应用</span></p>
                <p><span>第八章</span><span>FMEA在保险服务中的应用</span></p>
              </div>
              `,
        },
        {
          img: require("../../../assets/images/book8.png"),
          bookName: "基于流程和战略的组织设计",
          price: "暂无报价",
          bookDetail:
            "本书是介绍企业的如何基于企业战略方向和管理流程进行组织设计，以及组织设计的方法和原则，以促使企业达到战略目标。",
          textContain: `  
             <p class="bookText">
              本书编写历时两年多，呈现在读者面前的已经是第9次修改稿，并且笔者相信，当笔者看到正式出版后的书时，肯定又会有许多不满意之处。究其原因，不仅仅是自身对内容的追求，更重要的是外部环境的变化确实太快了——现实中一些曾经辉煌过的公司的破产以及更多新公司的迅速崛起，所有这些都在无情地冲击着企业管理的权威理论，使人们不得不重新认识我们的内外部环境及所面临的挑战。在这种背景下，对组织设计的理论和方法也必须再度审视，以适应甚至于超越社会和经济条件的变化，这也是本书的出发点。
              </p>
              <p class="textIndent">组织设计不是新概念，但在经历了20世纪末和21世纪初不断发生的公司危机之后，股东、社会和员工们都从不同角度关注着企业的组织设计，什么样的组织结构和组织运行模式才能同时保证安全、高效和创造价值？事实上，这一切的基础都依赖于核心流程，如果说和传统的组织设计有什么不同的话，那么其关键性区别就是是否关注核心流程，而核心流程的根本标志就是能否为顾客创造价值，若不能为顾客创造价值，那么一个组织就不会有真正的长远利益。</p>
              <p class="textIndent">所以，要追求价值最大化或者狭义的利益最大化，关键在于核心流程本身的结构和优化。离开了核心流程，组织设计就不可能是最优的，由此而产生的管理系统自然不可能满足安全、高效和价值创造的要求。</p>
              <div class="bookSection">
                <p>【本书分为12个章节：】</p>
                <p>
                <span>第一篇</span><span style="margin-right: 20px;">核心流程 </span><span>关注价值创造</span>
              </p>
              <p><span>第1章</span><span>发展之本— —探索核心竞争力</span></p>
              <p><span>第2章</span><span>认识流程— —特征及其分析工具</span></p>
              <p><span>第3章</span><span>和信念流程甘— —识别、描述和改进</span></p>
              <p><span>第4章</span><span>流程管理— —优化永无止境</span></p>
              <p>
                <span>第一篇</span><span style="margin-right: 20px;">战略管理 </span><span>切勿迷失方向</span>
              </p>
                    <p><span>第5章</span><span>解读战略— —视角的思考</span></p>
              <p><span>第6章</span><span>战略基础— —加开SWOT分析的面纱</span></p>
              <p><span>第7章</span><span>制定和实施战略——克服变革的阻力</span></p>
              <p>
                <span>第三篇</span><span style="margin-right: 20px;">组织设计 </span><span>基于流程和战略</span>
              </p>
              <p><span>第8章</span><span>基础背景——为什么要设计组织</span></p>
              <p><span>第9章</span><span>卓越领导——组织创新的灵魂</span></p>
              <p><span>第10章</span><span>组织结构——规模、技术和信息</span></p>
              <p><span>第11章</span><span>设计原则——注重可操作性</span></p>
              <p><span>第12章</span><span>程序要点——现实中的组织设计</span></p>
              </div>
              `,
        },
        {
          img: require("../../../assets/images/book12.png"),
          bookName: "设计和开发的理解与应用",
          price: "暂无报价",
          bookDetail:
            "本书从企业设计和开发管理的实际出发，去剖析和理解ISO9001中设计和开发的要求，帮助企业将国际标准要求融入到企业设计开发管理中，而非生搬硬套。",
          textContain: `  
             <p class="bookText">
              从1987年第一版标准发布至今，设计和开发一直是ISO9001实施和认证的难点，这除了它涉及的条款所占的比例最大外，还因为相对于其他条款而言涉及的专业程度较深。组织的设计受到行业特征的制约而遵循着自身的习惯模式，具有产品范围的设计专业和系统特征，这种系统特征无可避免地带有管理的性质。但设计人员往往不理解9001所要求的设计系统，当面对标准条款的要求时就似乎觉得自己不懂得设计和管理，本书从企业的实际出发，去剖析和理解ISO9001中涉及设计和开发的要求。
              只要企业实施ISO9001，要么将标准要求融入他们的设计系统中，要么将他们的设计系统融入标准要求中，这两种出发点所产生的系统效果完全不一样。但由于普遍存在对设计和开发理解的困难，结果往往是后者居多，照搬条款，教条和形式化。其实，企业是因为管理的需要而采纳标准，而不是因为标准才需要管理。企业希望的是将ISO9001要求纳入他们的设计系统后仍然保持着一种自然的而不是扭曲的状态，是提高设计的效率而不是增加负担。ISO9001所强调的过程方法正是以过程反映条款，而不是以条款反映过程。
            </p>
              <div class="bookSection">
                <p>【本书分为5个章节：】</p>
                <p><span>第一章</span><span>设计和开发概论</span></p>
                <p><span>第二章</span><span>设计和开发的要求</span></p>
                <p><span>第三章</span><span>设计和开发的类型</span></p>
                <p><span>第四章</span><span>设计和开发文件及控制</span></p>
                <p><span>第五章</span><span>概念区别及其术语解释</span></p>
              </div>
              `,
        },
        {
          img: require("../../../assets/images/book7.png"),
          bookName: "基于过程的质量管理体系策划",
          price: "暂无报价",
          bookDetail:
            "本书介绍了以顾客为关注焦点的企业，应该如何识别管理过程，并关切以过程为基础建立质量管理体系，最终建立起以顾客为导向的管理模式。",
          textContain: `  
             <p class="bookText">
              从1987年第一版标准发布至今，设计和开发一直是ISO9001实施和认证的难点，这除了它涉及的条款所占的比例最大外，还因为相对于其他条款而言涉及的专业程度较深。组织的设计受到行业特征的制约而遵循着自身的习惯模式，具有产品范围的设计专业和系统特征，这种系统特征无可避免地带有管理的性质。但设计人员往往不理解9001所要求的设计系统，当面对标准条款的要求时就似乎觉得自己不懂得设计和管理，本书从企业的实际出发，去剖析和理解ISO9001中涉及设计和开发的要求。
              只要企业实施ISO9001，要么将标准要求融入他们的设计系统中，要么将他们的设计系统融入标准要求中，这两种出发点所产生的系统效果完全不一样。但由于普遍存在对设计和开发理解的困难，结果往往是后者居多，照搬条款，教条和形式化。其实，企业是因为管理的需要而采纳标准，而不是因为标准才需要管理。企业希望的是将ISO9001要求纳入他们的设计系统后仍然保持着一种自然的而不是扭曲的状态，是提高设计的效率而不是增加负担。ISO9001所强调的过程方法正是以过程反映条款，而不是以条款反映过程。
            </p>
            <p class="bookText">
              从理念上“以顾客为中心”到操作上“以过程为中心”是ISO9001提出的重要原则，它们两者的结合就得出了“以过程为基础的质量管理体系模式”，这意味着标准回答了市场管理和过程管理的源头究竟在哪里的根本性问题。以标准的态度，推出一种管理模式也标志着它的探索取得了重要的成果，并且是得到了证实的成果。该模式对组织建立和完善体系具有深远的指导意义。
            </p>
            <p class="textIndent">
              诚然，笔者在深入探讨该模式时体会到，标准确实有一句顶一万句的功效，要真正贯彻好它并非易事。加之考虑到体系的建立一直存在着以下几个方面的困难：
             <br/>
             （1）基本概念的理解；
             <br/>
             （2）质量策划；
             <br/>
             （3）规范性与灵活性的处理；
             <br/>
             （4）职责和权限的合理规定；
             <br/>
             （5）文件的操作性。
              </p>
              <div class="bookSection">
                <p>【本书分为5个章节：】</p>
                <p><span>第一章</span><span>基本概念和原理</span></p>
                <p><span>第二章</span><span>质量管理体系策划</span></p>
                <p><span>第三章</span><span>控制的四个层次</span></p>
                <p><span>第四章</span><span>职能、职责和职权</span></p>
                <p><span>第五章</span><span>体系文件编写</span></p>
              </div>
              <p class="bookText">标准的思想是应该推崇的，是值得我们深入研究的，只有在领悟标准思想的情况下才能真正理解建立ISO9000体系的意义和完全明白应该有怎样的付出，这绝对不是一蹴而就的事情。对此，本书提供了一些可行的方法和大量的例子，使体系的建立更具操作性。</p>
              <p class="textIndent">当组织将展望未来寄托在自己所建立的体系上时，当我们将体系看作是一件工艺品去雕琢而不再想起那是ISO9000时，那体系就一定很棒。要是这样，该书所展示的“以过程为基础的质量管理体系模式”或许能给你带来启发和帮助。</p>
              `,
        },
        {
          img: require("../../../assets/images/book13.png"),
          bookName: "ISO9000标准解说",
          price: "暂无报价",
          bookDetail:
            "本书系统的讲述了ISO9001标准的知识，从管理体系的基本概念、术语到管理体系的具体标准条款都有详细解说，是质量管理体系不可多得的教材类书籍。",
          textContain: `  
             <p class="bookText">
              中国标准出版社与我司继推出《ISO14001理论与实践丛书》后又共同策划了一套《ISO9001理论与实践丛书》，本书是其中之一。
              </p>
              <p class="bookText">从1987版到1994版，再到2000版，ISO9000系列标准带着时代的深深烙印走过了14个年头。如果说10年前ISO9000在中国被蒙上神秘面纱可以被理解的话，则今天应是还其本来面目的时候了，而这首先应立足于对标准的理解。的确，ISO9000标准的编制者集中了世界上的知名专家，是从广泛的立场上深入探讨的结果。但是，为了使其在不同国度、不同行业都能应用，不可避免地造成理解的困难。例如，其用语的高度概括性带来的可能多解性以及做到什么样的程度才算满足标准的要求？对于同样一个ISO9001不同的咨询机构、不同的认证机构、不同的人都可能有不同的解释。因此，实施ISO9000标准应首先理解标准本身的思想和内涵。
              </p>
              <p class="textIndent">本书充分吸收了多年来ISO9001实施的经验和教训，处于为读者提供理解途径的目的，详细解释了ISO9001和ISO9000所涉及的内容和术语，是一本不可多得的工具书。</p>
              `,
        },
        {
          img: require("../../../assets/images/book1.png"),
          bookName: "ISO14001标准解说",
          price: "暂无报价",
          bookDetail:
            "本书系统的讲述了ISO14001标准的知识，从管理体系的基本概念、术语到管理体系的具体标准条款都有详细解说，是环境管理体系不可多得的教材类书籍。",
          textContain: `  
             <p class="bookText">
              为了在国内大力宣传ISO14001标准，帮助企业建立环境管理体系，中国标准出版社与我司共同策划了一套《ISO14001理论与实践丛书》，本书是其中之一。
              </p>
              <p class="bookText">ISO14001《环境管理体系一一规范及使用指南》具有高度概括性、系统性的特点，这也决定了它在一定程度上的抽象性。企业要建立的是一个具体的环境管理体系，其依据自然是标准本身。尽管ISO14001：1996发布已近4年，但迄今为止大家对其理解仍有许多不同。本人在实际工作中也遇到一些矛盾的案例一一不同的认证机构对某些条款的要求竟然是两个方向。因此，正确理解标准是建立体系和认证的根本基础。
              </p>
              `,
        },
        {
          img: require("../../../assets/images/book5.png"),
          bookName: "环境管理体系典型案例",
          price: "暂无报价",
          bookDetail:
            "本书为企业建立环境管理体系的各个阶段和过程都提供了丰富经典案例，书中案例均出自笔者辅导企业的真实案例，包括广州本田、东风本田发动机、金霸王、ICI太古漆等知名企业。",
          textContain: `  
             <p class="bookText">
              为了在国内大力宣传ISO14001标准，帮助企业建立环境管理体系，中国标准出版社与我司共同策划了一套《ISO14001理论与实践丛书》，本书是其中之一。
              </p>
              <p class="bookText">本书在环境管理体系所涉及的主要方面都给出了典型案例，包括环境方针、环境因素、目标和指标、环境管理方案、员工培训、体系文件等，所有提供案例的公司均为安索管理顾问有限公司的客户，其中包括了欧洲、美国、日本、台湾和香港在中国大陆投资的企业，也包括了国内著名企业，这些企业中不乏国际知名品牌。从这些企业获得ISO14001证书的情况看，认证机构所在的国家或地区分别是中国、英国、法国、德国、瑞士、挪威、香港等，可以认为有相当程度的代表性。
              </p>
              <p class="textIndent">不过，尽管将本书推荐给读者，但是其内容仅起参考作用——任何照办模式的办法都不可能建立起真正有效的环境管理体系。我们不否认ISO14001的作用，同时也反对将其作用无限扩大化的做法，近来媒体走火入魔式的夸大宣传不仅不能．帮助行业的发展，反而有损ISO14001本来的健康。全面认识ISO14001和它的作用并还其本来面目才是唯一正确的选择。这也是本书的目的所在。</p>
              `,
        },
        {
          img: require("../../../assets/images/book6.png"),
          bookName: "环境管理体系内审员培训教程",
          price: "暂无报价",
          bookDetail:
            "本书为实施环境管理体系的企业内审员讲述了审核的基本知识和方法，以及分享了大量的审核案例。",
          textContain: `  
             <p class="bookText">
              为了在国内大力宣传ISO14001标准，帮助企业建立环境管理体系，中国标准出版社与我司共同策划了一套《ISO14001理论与实践丛书》，本书是其中之一。
              </p>
              <p class="bookText">在ISO14001环境管理体系中，内部审核是一个必不可少的环节。具体实施内部环境管理体系审核时由于绝大多数建立环境管理体系的组织已通过了ISO9001认证，自然会充分利用内部质量管理体系审核的经验和手法。毫无疑问，ISO9001与ISO14001有着很强的兼容性，内部环境管理体系审核应最大限度地借鉴ISO9001的成果。但是，这并不意味着简单的照搬，因为二者毕竟存在着许多不同，特别是没有将二者一体化的组织，更应考虑这些不同点。例如，在对象、成本转移、覆盖范围和对持续改进的要求方面，ISO9001和ISO14001均有不同，在制定审核计划、任命审核员和编制审核清单时都有着各自的特点。有效的内部环境管理体系审核有赖于两个基本点，一是审核员正确理解ISO14001标准的要求和企业的自身特点，二是审核员对审核原则、方法和技巧的掌握。
              </p>
              <p class="textIndent">另外，尽管内部审核和第三方审核的目的有所不同，但第三方审核的方式、方法和技巧却非常值得我们参考。从一定意义上讲，内部审核的难度比第三方审核更高，一个出色的内审员所需要的能力并不比外审员低。</p>
              `,
        },
        {
          img: require("../../../assets/images/book10.png"),
          bookName: "质量环境一体化管理体系",
          price: "暂无报价",
          bookDetail:
            "本书是最早提及管理体系一体化的书籍，凭借笔者多年辅导企业的经验，观察到企业的潜在需求，为了更高效的运行多个管理体系，一体化体系是必然的结果，因此本书讲述了一体化体系的建立原则和案例研究。",
          textContain: `  
             <p class="bookText">
              为了在国内大力宣传ISO14001标准，帮助企业建立环境管理体系，中国标准出版社与我司共同策划了一套《ISO14001理论与实践丛书》，本书是其中之一。
              </p>
              <p class="bookText">
                自1996年9月国际标准化组织发布ISO14001以来，其与ISO9001的关系就引起了许多人士的兴趣，大家都认为二者之间存在着一体化的条件，不应将它们割裂开来。
                但在具体实施时，绝大多数企业并未将ISO14001和ISO9001相联系，而是分别建立质量管理体系和环境管理体系。究其原因，不外乎四方面：
              </p>
              <p class="textIndent">
                <span>1</span>没有认识到二者之间的有机关联，找不出一体化的途径；
              </p>
          
              <p class="textIndent">
                <span>2</span>受中国特殊国情的制约（ISO9001和ISO14001分属不同的部门管理，有不同的认证机构），被错误观念误导；
              </p>
              <p class="textIndent">
                <span>3</span>通过ISO14001认证仅仅是为了市场宣传的需要，其实并未真正执行；
              </p>
              <p class="textIndent">
                <span>4</span>受辅导的咨询老师水平和经验的限制。
              </p>
              <p class="textIndent">
                事实上，国际上的知名企业很少将ISO14001标准下的环境管理体系独立于其他体系之外，他们非常重视一体化，更多着眼于体系的有效性、充分性和适应性。随着中国走向世界贸易组织（WTO），企业面临着越来越多的体系要求，如果分别建立各式各样的管理体系，将会增加多少管理负担和浪费多少资源！
              </p>
              <p class="textIndent">
                毫无疑问，企业经营的目的是追求经济利益。因此，任何管理体系不能无视这一前提。正因为如此，建立ISO9001/ISO14001一体化管理体系的出发点应考虑企业自身利益。只有将质量管理、环境管理和企业的发展紧密联系在一起，才可能使国际标准化组织制定ISO9001和ISO14001标准的初衷得以实现。本书作者在这种认识的基础上进行了研究和实践，也得出许多可供借鉴的经验，作为这种尝试的总结之一，我们将本书奉献给读者，但愿能提供一些有价值的参考。
              </p>
              `,
        },
        {
          img: require("../../../assets/images/book13.png"),
          bookName: "ISO9000银行业实施指南",
          price: "暂无报价",
          bookDetail:
            "本书结合银行业的经营实际和ISO9001标准的要求，对银行业推行质量管理的推进过程进行探讨，让银行从业者可以由表及里、深入浅出的理解ISO9000精髓。",
          textContain: `  
             <p class="bookText">
              中国标准出版社与我司继推出《ISO14001理论与实践丛书》后又共同策划了一套《ISO9001理论与实践丛书》，本书是其中之一。
             </p>
             <p class="bookText">
              21世纪的世界经济，将逐步步入“经济一体化，贸易自由化”的发展轨道。中国入世后，国内经济也将逐步融入“两化"之中，这意味着市场经济的竞争已经冲破国界。我国企业要想在新的经营环境中有效发展，必须重视质量管理。也就是说，质量已经成了21世纪企业发展的共同主题。国际标准化组织（ISO）所制定的质量管理体系标准（即ISO9000标准），无疑是各国企业质量管理求得认同的“基础语言"，因此，企业的产品或服务导入ISO9000标准已经成为国与国之间产品竞争与合作的基本条件。在世纪之交的2000年，中国农业银行湖南省分行把金融竞争的焦点放在服务创新上，率先在个人业务服务系统中导入ISO9000标准，并取得了积极成果，这是一种有益尝试，更是一种敢为人先的服务创新之举。我们结合银行业的经营实际，组织编写了《ISO9000：2000银行业实施指南》一书，是对银行业质量管理的探讨，更是对ISO9000标准渗透银行经营管理的总结。本书所涵盖的六章内容，既按银行业贯标的基本顺序加以组织，又兼顾了ISO9000标准的推进过程，能够让读者由表及里、浅入深出地理解ISO9000精髓。
            </p>
             `,
        },
      ],
      currentList: [],
    };
  },
  watch: {
    $route(n, o) {
      if (n.path == "/book-all-detail" && o.path == "/book-all-detail" && !o.query.queryBookName) {
        this.$router.go(-1);
      }
    },
  },
  mounted() {
    if (this.$route.query.queryBookName) {
      this.bookList.map((i) => {
        if (i.bookName == this.$route.query.queryBookName) {
          this.$router.push({ query: null });
          this.$router.push("./book-detail-page?item=" + JSON.stringify(i));
        }
      });
    }
    this.getNew(0);
    this.numpage = this.bookList.length;
  },
  methods: {
    getNew(value) {
      this.currentList = this.bookList.slice(value, value + this.limit);
    },
    toPage(item) {
      this.$router.push("./book-detail-page?item=" + JSON.stringify(item));
    },
  },
};
</script>

<style lang="less" scoped>
.book-all-detail {
  position: relative;
  width: 100%;
  padding-top: 60px;
  .img1 {
    position: absolute;
    z-index: 0;
  }
  .img2 {
    position: relative;
    z-index: 9999;
    padding-top: 1%;
  }
  .contain {
    position: relative;
    z-index: 9999;
    display: flex;
    width: 78%;
    margin-left: 13%;
    .left {
      width: 20%;
      padding: 0 0.2rem;
      background: #f2faff;
      border: 1px solid #dfdfdf;
      text-align: center;
      margin-top: 2%;
      margin-right: 3.5%;
      height: 4.7rem;
      img {
        width: 2.8rem;
      }
      p:nth-of-type(1) {
        padding: 0.3rem 0 0.2rem 0;
        color: #000000;
      }

      p:nth-of-type(2) {
        padding: 0.2rem 0;
        color: #858899;
      }
    }
    .right {
      margin-top: 2.5%;
      .title {
        color: #333333;
        font-weight: bold;
      }
      .book-list {
        display: flex;
        margin-top: 0.1rem;
        flex-wrap: wrap;
        .book {
          position: relative;
          background: #ffffff;
          display: flex;
          padding: 0.25rem 0.2rem 0.2rem 0.3rem;
          width: 43%;
          margin-right: 2%;
          box-shadow: 0px 1px 5px 1px #e0dfdf;
          border-radius: 5px;
          margin-bottom: 2%;
          img {
            width: 1.1rem;
            margin-right: 0.25rem;
            height: 2rem;
          }
          div {
            .p1 {
              color: #532c1b;
              font-weight: bold;
              margin-bottom: 0.03rem;
            }
            .p2 {
              color: #999999;
              font-size: 0.16rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
            }
            .el-button {
              position: absolute;
              bottom: 0.3rem;
              background: #344cc1;
              color: #ffffff;
              padding: 0 22px !important;
              border-radius: 5px !important;
            }
          }
        }
      }
    }
  }
}
</style>