<template>
  <div class="page">
    <ul>
      <li><a class="prev" :plain="true" @click="goPrePage">上一页</a></li>
      <li v-for="(i, index) in showPageBtn" :key="index" @click="pageOffset(i)">
        <a
          v-if="i"
          :class="{
            on: i === currentPage,
          }"
          >{{ i }}</a
        >
        <a v-else>···</a>
      </li>
      <li><a class="next" plain="true" @click="goNextPage">下一页</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    num: Number,
    limit: Number,
  },
  data: () => ({
    offset: 0,
  }),
  computed: {
    prePage() {
      return this.offset !== 0 && this.num;
    },
    nextPage() {
      return this.offset + this.limit < this.num && this.num;
    },
    totalPage() {
      return Math.ceil(this.num / this.limit);
    },
    currentPage() {
      return Math.ceil(this.offset / this.limit) + 1;
    },
    showPageBtn() {
      const pageNum = this.totalPage;
      const index = this.currentPage;
      if (pageNum <= 5) return [...new Array(pageNum)].map((v, i) => i + 1);
      if (index <= 2) return [1, 2, 3, 0, pageNum];
      if (index >= pageNum - 1)
        return [1, 0, pageNum - 2, pageNum - 1, pageNum];
      if (index === 3) return [1, 2, 3, 4, 0, pageNum];
      if (index === pageNum - 2)
        return [1, 0, pageNum - 3, pageNum - 2, pageNum - 1, pageNum];
      return [1, 0, index - 1, index, index + 1, 0, pageNum];
    },
  },
  methods: {
    pageOffset(i) {
      if (i === 0 || i === this.currentPage) return;
      this.offset = (i - 1) * this.limit;
      this.$emit("getNew", this.offset);
    },
    goPrePage() {
      if (!this.prePage) {
        // alert("这里是第一页!");
        // this.$message({
        //   showClose: true,
        //   message: "这是第一页哦！",
        //   type: "warning",
        // });
        return;
      }
      this.offset -= this.limit;
      this.$emit("getNew", this.offset);
    },
    goNextPage() {
      if (!this.nextPage) {
        // alert("已经是最后一页了呢~");
        // this.$message({
        //   showClose: true,
        //   message: "这是最后一页啦！",
        //   type: "warning",
        // });
        return;
      }
      this.offset += this.limit;
      this.$emit("getNew", this.offset);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  ul {
    li {
      display: inline-block;
      margin-right: 3px;
      a {
        display: inline-block;
        font-size: 12px;
        color: #333;
        border: 1px solid #ccc;
        padding: 7px 14px;
      }
      .prev,
      .next {
        font-weight: bold;
      }
      .on {
        background-color: #0255c5;
        border-color: #0255c5;
        color: #fff;
      }
      a:hover {
        background-color: #0255c5;
        border-color: #0255c5;
        color: #fff;
      }
    }
  }
}
</style>
